@import url('https://fonts.googleapis.com/css?family=Manrope');

/* * {
  box-sizing: border-box;
  margin: 10;
  padding: 10;
} */

html {
  scroll-behavior: smooth;
}

body {
  /* font-family: 'Manrope', cursive; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  min-height: 94vh;
  margin: auto;
}

/* Survey forms */

.sv_panel .sv_title {
  display: none;
}

.custom-css-class {
  /* background-color: #007bff; */
  color: black;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.custom-css-class:hover {
  background-color: #0056b3;
}

.navigation-block {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  background-color: #fff;
}

.navigation-page-selector {
  flex-basis: 240px;
}
.navigation-progress-container {
  display: flex;
  flex-direction: column;
  align-items: end;
}
.navigation-buttons-container {
  display: flex;
  gap: 8px;
}
.navigation-button {
  line-height: 24px;
  font-weight: 600;
  font-family: 'Open Sans';
  font-size: 16px;
  padding: 12px 32px;
  border: none;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  background-color: #19b394;
  color: #fff;
  cursor: pointer;
  outline: none;
}
.navigation-button:focus {
  color: #19b394;
  background-color: #fff;
  box-shadow: 0 0 0 2px #19B394;
}
.navigation-button:hover {
  color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  background-color: #14A48B;
}
.navigation-text-container {
  position: relative;
  width: 100%;
}
.navigation-text {
  position: absolute;
  right: 0;
  top: 8px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.45);
}

.btn-more-info {
  color: blue;
  background: rgba(0, 0, 0, 0);
  border: none;
  font-size: 14px;
  font-family: inherit;
  font-weight: bold;
  outline: none;
  padding: 8px 24px;
  text-align: left;
  border-radius: 100px;
}

.btn-more-info:hover {
  color: #1E90FF;
}

.btn-more-error {
  background-color: rgb(26, 179, 148);
  color: black;
}

.btn-more-error:hover {
  background-color: rgb(26, 179, 148);
  color: #CE2C60;
  ;
}

.survey-editor-max-height {
  height: 100
}

.survey-html-question {
  margin-right: -1.3rem; 
  margin-left: -1.3rem;
  margin-top: -0.5rem;
}

.survey-html-panel {
  margin: 2.5rem 1rem 0rem 1rem;
}

.survey-title-hidden {
  color: #FFF;
  margin: -14px;
}

.survey-title-margin-top {
  margin-top: 36px;
}

.survey-title-font-weight-normal {
  font-weight: normal;
  font-size: 16px;
}

.survey-content-hidden {
  display: none;
}

.survey-header-column {
  display: flex;
  flex-direction: column;
}

.survey-html-tag-actions {
  position: absolute;
  bottom: 72px;
  right: 0px;
}

.survey-bg-black {
  background-color: #000;
}



